import React, { Component, Fragment } from 'react';
import {
  Container,
  Header,
  Icon,
  Image,
  Menu,
  Dropdown,
  Divider,
  Responsive,
  Sidebar,
  Button,
  Segment,
  Popup
} from 'semantic-ui-react';
import { withRouter,Link } from "react-router-dom";
import axios from 'axios';
//import allRoutes from './../routes/allroutes.jsx';
 import logo from './../../assets/img/logo.png';
import './../../assets/css/main.css';
function checkIfPreview() {
  var url = window.location.href;
  if(url.indexOf("/preview/")!==-1){
    return true;
  }
  return false;
}
class HeaderFile extends Component{
  state = {user:{},hover_header:false,sidebarOpened:false,disable_tabs:false}
  componentDidMount(){
    var user = JSON.parse(localStorage.getItem('user_details'));
    if(user) {
        if(user.plan=='Free1') {
      this.setState({disable_tabs:true})
    }
    }
    if(!checkIfPreview()) {
    if(localStorage.user_details){
      var user = JSON.parse(localStorage.user_details);
      this.setState({user:user})

    }
    else{
      this.props.history.push('/')
    }
    }
  }

componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.children !== prevProps.children) {
    if(localStorage.user_details){
       var user = JSON.parse(localStorage.user_details);
        this.setState({user:user})
    }
  
  }
}

  logout = () => {
    //console.log(window.tp,"tp")
    // if(window.tp){
    //   window.tp.pianoId.logout();
    // }
    axios.get('logout').then(done => {
      localStorage.removeItem('user_details');
      this.props.history.push('/')
    }).catch(err => {
      localStorage.removeItem('user_details');
      this.props.history.push('/')
      console.log(err);
    })
    
  }

  hoveron = (name) =>  event => {
    if(!this.state.disable_tabs) {
      if(name){
      this.setState({hover_header:name});
    }
    }
  }

  hoveroff = (event)  => {
    //console.log('leave')
    
      this.setState({hover_header:null})
  
  }

  switchRoute = (route) => event => {
    // console.log("route",route);
    var plan = JSON.parse(localStorage.getItem('user_details')).plan;
    if(plan!=='Free1') {
      this.props.history.push(route);
    }
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  showOffer = () =>{
    var tp = window.tp;
    tp.offer.show({
    offerId: "OFR409U3C77F",
    templateId: "OT7THPEXN5MU"
  });
  }

  render(){
    //console.log(this.state.user,"u")
    var {disable_tabs}=this.state;
    const {user,sidebarOpened} = this.state;
    return(
      <Fragment>
      <style>
        {`.ui.secondary.inverted.pointing.menu .item.small-icon{font-size:13px !important}
         .disabled{
          ponter-events:none;
        }` }
      </style>
        <Responsive minWidth="768" className="main-header2">
          {this.props.history.location.pathname!='/' && <Menu   
             borderless
              size='large'
              secondary
              pointing
              inverted
             
              className="main-header"
            >
              <Container fluid>

              <Menu.Item as={Link} to={disable_tabs?"/search-events":"/search-events"}>
              <Image size="medium" src={logo} style={{width: '201px',paddingBottom: '5px'}}/>
             
              </Menu.Item>
              <Menu.Item style={{margin: '0px auto',paddingBottom: '0px'}}>
                <Menu.Item className="main-links" onMouseEnter={this.hoveron('search-events')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/search-events'?true:this.state.hover_header=='search-events'?true:false}   as={Link} to="/search-events" >
                  Search Events
                 <Divider hidden />
                </Menu.Item>
                
 <Menu.Item className="main-links"   style={{pointerEvents:'initial'}} onMouseEnter={this.hoveron('compare-peers')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/compare-peers'?true:this.state.hover_header=='compare-peers'?true:false} onClick={this.switchRoute('/compare-peers')} id="compare-peers">Compare Peers {this.state.user.plan=='Free' && <Icon style={{marginLeft: '3px', marginTop: '-3px'}} name="lock"/>}<Divider hidden /></Menu.Item>
                {/*<Menu.Item disabled ={disable_tabs} className="main-links"   style={{pointerEvents:disable_tabs?'none':'initial'}} onMouseEnter={this.hoveron('compare-peers')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/compare-peers'?true:this.state.hover_header=='compare-peers'?true:false} onClick={this.switchRoute('/compare-peers')} >Compare Peers {this.state.user.plan=='Free' && <Icon style={{marginLeft: '3px', marginTop: '-3px'}} name="lock"/>}<Divider hidden /></Menu.Item>*/}
                {/*{this.state.user.plan=='Free' && <Menu.Item className="main-links" disabled={this.state.user.plan=='Free'}  style={{color:'grey'}} >My Events &nbsp; {this.state.user.plan=='Free' &&  <Popup size="mini" content='Upgrade to access this feature' trigger={<Icon name="lock"/>} />}<Divider hidden /></Menu.Item>}
                {this.state.user.plan!='Free' && <Menu.Item className="main-links" onMouseEnter={this.hoveron('my-events')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/my-events'?true:this.state.hover_header=='my-events'?true:false}   as={Link} to="/my-events">My Events<Divider hidden /></Menu.Item>}
                */}
                 <Menu.Item className="main-links" onMouseEnter={this.hoveron('my-events')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/my-events'?true:this.state.hover_header=='my-events'?true:false} as={Link}  style={{pointerEvents:'initial'}}  onClick={this.switchRoute('/my-events')} id="my-events">My Events<Divider hidden /></Menu.Item>
                </Menu.Item>

              {/*<Menu.Item disabled ={disable_tabs} className="main-links" onMouseEnter={this.hoveron('my-events')} onMouseLeave={this.hoveroff} active={this.props.history.location.pathname=='/my-events'?true:this.state.hover_header=='my-events'?true:false} as={Link}  style={{pointerEvents:disable_tabs?'none':'initial'}} to="/my-events">My Events<Divider hidden /></Menu.Item>
                </Menu.Item>*/}
                 {/*<Menu.Item position='right'>
   
                  <Button as={Link} to="/login" inverted={this.props.history.location.pathname!=='/'?false:!fixed} basic={this.props.history.location.pathname!=='/'?true:fixed} primary={this.props.history.location.pathname!=='/'?true:fixed} >
                    Log in
                  </Button>
                  <Button as={Link} to="/signup" inverted={this.props.history.location.pathname!=='/'?false:!fixed}  primary={this.props.history.location.pathname!=='/'?true:fixed} style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
                  
                {user &&<Menu.Item position="right">  <Dropdown  trigger={<span><Image  src={user.pic?user.pic:defaultPic} avatar/>{user.name}</span>} pointing='top left' icon={null} >
                  <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/user-profile"><Icon name="setting" /> Account </Dropdown.Item>
                <Dropdown.Divider/>
                 <Dropdown.Item onClick={this.logout}><Icon name="sign out" /> Log Out </Dropdown.Item>
                   </Dropdown.Menu>
                  </Dropdown>

              </Menu.Item>}*/}
                 <Menu.Item  position="right">
                   <Menu.Item className="white-color disabled"  as={Link} to="/account" style={{display:'none',paddingRight:"0",pointerEvents:'none'}}>
                   <Icon circular inverted  color="blue" name="user"/>
                 </Menu.Item>
                 <Menu.Item className="white-color" as={Link} to="/help" style={{paddingRight:"0"}}>
                   <Icon circular inverted  color="blue" name="info"/>
                 </Menu.Item>               
                 <Menu.Item className="white-color small-icon" onClick={this.logout} style={{paddingBottom:"14px"}}>
                   Logout
                 </Menu.Item>                                
                  </Menu.Item>
                
              </Container>
            </Menu>}  
             {this.props.children}
        </Responsive>

         <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyMobile.maxWidth}>
             <Fragment><Sidebar
                    as={Menu}
                    animation='push'
                    onHide={this.handleSidebarHide}
                    vertical
                    size="large"
                    visible={sidebarOpened}
                    className="mobile-side"
                  >
                          <Menu.Item onClick={this.handleSidebarHide}  as={Link} to="/search-events" >
                            Search Events
                          </Menu.Item>
                          <Menu.Item onClick={this.handleSidebarHide}  as={Link} to="/compare-peers" id="compare-peers">Compare Peers</Menu.Item>
                          <Menu.Item onClick={this.handleSidebarHide} as={Link} to="/my-events" id="my-events">My Events</Menu.Item>
                        <Menu.Item onClick={this.handleSidebarHide} as={Link} to="/help">
                  Help
                  </Menu.Item>
                 {/*} <Menu.Item>*/}
                <Menu.Item onClick={this.handleSidebarHide} as={Link} to="/account">My Profile</Menu.Item>
                 <Menu.Item onClick={this.logout}>Logout</Menu.Item>

                    
                  </Sidebar>
          </Fragment>
                  
              <Sidebar.Pusher dimmed={sidebarOpened} >
                {this.props.history.location.pathname!='/' && <Segment
                      
                      textAlign='center'
                     style={{ minHeight:this.props.history.location.pathname=='/'?80:52 ,padding:0 }}
                      vertical
                    >
                      
                        <Menu  className="main-header" borderless fluid  inverted pointing  size='large'>
                          <Menu.Item onClick={this.handleToggle} style={{zIndex: 100}}>
                            <Icon name='sidebar' size="large"  />
                          </Menu.Item>
                          <Menu.Item>
                             <Image as={Link} to="/" size="medium" src={logo} verticalAlign='middle'  />
                          </Menu.Item>
                        </Menu>
                     
                     {/*{this.props.history.location.pathname=='/' && <HomepageHeading mobile />}*/}
                    </Segment>}
                    {this.props.children}
                    
                  </Sidebar.Pusher>
      </Responsive>
      </Fragment>
         
      )
  }
}

export default withRouter(HeaderFile);