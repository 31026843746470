import React, { Component, Fragment} from 'react';
import {
  Button,
  Container,
  Header,
  Segment,
  // Label,
  Divider,
  // List,
  Grid,
  // Icon,
  Checkbox,
  Form,
  Message,
   Dimmer,
  Loader, 
} from 'semantic-ui-react';
import { withRouter, Link } from "react-router-dom";
import './../../assets/css/main.css';
import axios from 'axios';

class Account extends Component{
  state = {user_details:{},industries:[],errorpass:false,erroruser:false,errormsg:'',savesuccesfuluser:false,savesuccesfulpass:false,loading:false,maxind:3,render:false,plan:""}
  componentDidMount(){
    var that = this;
       var tp = window.tp;
        setTimeout(function(){
              tp.push(["init", function() {
                tp.experience.init();
                var user = tp.pianoId.getUser();
                //console.log(user,"user")
              //console.log(tp.pianoId, tp.pianoId.isUserValid(),"p")
                    if(tp.pianoId.isUserValid()){
                      tp.myaccount.show({
                        displayMode: "inline",
                        containerSelector: "#my-account"
                    });
                      setTimeout(function(){that.setState({render:true})},800);
                    }
                    
                }]);
            },500)
    var user = JSON.parse(localStorage.getItem('user_details'));
    this.setState({user:user})
      if(user.plan=='Free'){
        this.setState({maxind:1,plan:user.plan})
      }
      else if(user.plan=='Month'){
        this.setState({maxind:3,plan:user.plan})
      }
      else{
        this.setState({maxind:5,plan:user.plan})
      }
    //var tp = window["tp"] || [];
      
         
    
    axios.get('user_profile').then(data => {
      this.setState({user_details:data.data[0]})
      var ind_selected = JSON.parse(data.data[0].industries);
     // console.log(ind_selected,"i")
      axios.get('fetch_industries').then(ind =>{
      if(ind.data.length>0){
        ind.data.map(obj => {
          obj.selected= false;
        })
        ind_selected.map(x => {
           ind.data.map(obj => {
             if(x==obj.id){
              obj.selected=true
            }
           })
           
          })
        this.setState({industries:ind.data})
        //console.log(ind.data,"ind")
      }
    }).catch(err => {
      console.log(err);
    })
    }).catch(err => {
      console.log(err);
    })

  }

  // Split in group of 5 items
  chunkArray=(myArray, chunk_size) =>{
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
         var myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

      return tempArray;
  };
  // Split in group of 5 items

  handleEdit=(name) => event =>{
    if(name){
      var user ={...this.state.user_details};
      user[name]=event.target.value;
      this.setState({user_details:user,made_changes:true,savesuccesful:false})
      // if(name == 'password' || name =='oldpass' || name == 'newpassword'){
      //   this.setState({error:false,errormsg:''})
      // }
    }
    // event.preventDefault();
    // console.log(event,"VALUE");
  };

  handleCheck=(obj) => (event,value) =>{
     var ind = [...this.state.industries];
    if(!obj.selected){
      var selected = ind.filter(x => x.selected==true);
      if(selected.length>=this.state.maxind){
        // console.log('here',ind)
        // sel.splice(-1,1);
        ind.map(o => {
        if(o.id==selected[selected.length-1].id){
          o.selected=false
        }
        if(o.id==obj.id){
          o.selected=true;
        }
      })
      }
      else{
          ind.map(o => {
        if(o.id==obj.id){
          o.selected=true;
        }
      })
      }
    }
    else{
     
      ind.map(o => {
        if(o.id==obj.id){
          o.selected=false;
        }
      })
     
    }
     this.setState({industries:ind,made_changes:true,savesuccesful:false})
    // console.log(value,"VALUE");
  };

  handleSubmit = () => {
    //this.setState({savesuccesful:false})
    if(this.state.made_changes){
      var user = {...this.state.user_details};
      if(this.state.industries.length!=0){
         var industries = this.state.industries.filter(obj => obj.selected == true).map(obj => obj.id);
         user.industries = JSON.stringify(industries);
         delete user.oldpass;
         delete user.newpassword;
         delete user.password;
        //console.log(user,"u")
        axios.post('update_user_profile',user).then(saved => {
          this.setState({savesuccesfuluser:true})
          setTimeout(() => {
            this.setState({savesuccesfuluser:false})
          },2000)
        }).catch(err => {
           this.setState({erroruser:true,errormsg:'We are facing some issues try again after some time.'})
          console.log(err);
        })
        var plan_name;
          if(this.state.user.plan=='Free'){
            var resources = localStorage.getItem('resources');
            resources = JSON.parse(resources);
            var free_id = process.env.REACT_APP_FREE_PLAN;
             plan_name = resources.filter(x => {
              if(x.rid==free_id){
                return x;
              }
            })

          }
           else if(this.state.user.plan=='Free1'){
            var resources = localStorage.getItem('resources');
            resources = JSON.parse(resources);
            var free_id1 = process.env.REACT_APP_FREE_PLAN1;
             plan_name = resources.filter(x => {
              if(x.rid==free_id1){
                return x;
              }
            })

          }
          else if(this.state.user.plan=='Month'){
            var resources = localStorage.getItem('resources');
            resources = JSON.parse(resources);
            var mtm_id = process.env.REACT_APP_MTM_PLAN;
            plan_name = resources.filter(x => {
              if(x.rid==mtm_id){
                return x;
              }
            })
          }
          else{
            var resources = localStorage.getItem('resources');
            resources = JSON.parse(resources);
            var ann_id = process.env.REACT_APP_ANN_PLAN;
            var etp_id = process.env.REACT_APP_ETP_PLAN;
            plan_name = resources.filter(x => {
              if(x.rid==ann_id || x.rid==etp_id){
                return x;
              }
            })
          }
        //var plan  = this.state.user.plan=='Free'?'Starter':this.state.user.plan=='Month'?'Flexible':'Premium'
         axios.post('record',{identity:1,industry_id:industries,via:'User Profile',plan_type:plan_name[0].name}).then(done => {

        }).catch(err => {
          console.log(err,"analytics error")
        })
  
        
      }
      else{
        this.setState({erroruser:true,errormsg:'Please choose atleast 1 industry to continue.'})
      }
     
     // console.log(industries,"i")

    
      
    }
  }

  handlePassword = () => {
        var user = {...this.state.user_details};
        //console.log(user,"u")
        if(user.password || user.newpassword || user.oldpass){
        if(!user.oldpass){
          this.setState({error:true,errormsg:'Please enter your old password'})
        }
        else if(user.password && user.newpassword){
          if(user.password.length<8){
              this.setState({errorpass:true,errormsg:"Your password should be atleast 8 characters"})
          }
          if(user.password != user.newpassword){
            this.setState({errorpass:true,errormsg:"Your password don't match"})
          }
          else {
            var user_obj = {};
            Object.keys(user).map(key => {
              if(key=='oldpass' || key=='newpassword' || key=='password'){
                user_obj[key] = user[key]
              }
            })
            axios.post('update_user_profile',user_obj).then(saved => {
               this.setState({savesuccesfulpass:true})
            }).catch(err => {
              if(err.response){
                if(err.response.data && err.response.data.includes('doesnt match')){
                  this.setState({errorpass:true,errormsg:'Your old password is incorrect.'})
                }
              }
              else{
                 this.setState({errorpass:true,errormsg:'We are facing some issues try again after some time.'})
              }
              console.log(err);
            })
          }
        }
        else if(!user.password || !user.newpassword){
           this.setState({errorpass:true,errormsg:'Please enter your new password'})
        }
      }
  }

  closeError = (type) => event => {
    //console.log('called')
    var name = 'error'+type;
    this.setState({[name]:false,errormsg:''})
  }

  closeSuccess = (type) =>  event => {
    var name = 'savesuccesful'+type;
    this.setState({[name]:false})
  }

  revertchanges = () => event => {
    axios.get('user_profile').then(data => {
      this.setState({user_details:data.data[0]})
      var ind_selected = JSON.parse(data.data[0].industries);
     // console.log(ind_selected,"i")
      axios.get('fetch_industries').then(ind =>{
      if(ind.data.length>0){
        ind.data.map(obj => {
          obj.selected= false;
        })
        ind_selected.map(x => {
           ind.data.map(obj => {
             if(x==obj.id){
              obj.selected=true
            }
           })
           
          })
        this.setState({industries:ind.data})
        //console.log(ind.data,"ind")
      }
    }).catch(err => {
      console.log(err);
    })
    }).catch(err => {
      console.log(err);
    })
  }

  render(){
        //const industries= ['Alternative Data','Artificial Intelligence','Banking','Blockchain','Capital Markets','Cryptocurrency','Finance/Economics','Fintech','Insurance','Lending','Payments','Real Estate','Regulation and Compiance','Technology','Wealth Management'];
        //var result = this.chunkArray(industries, 8);
     
      
    return(
        <Container fluid>
         
           <Dimmer.Dimmable as={Segment}  className="account-segment" blurring  dimmed={!this.state.render} >
          <Dimmer active={!this.state.render} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          
            <Fragment>
            <Header as='h2'>My Profile</Header>
            <Divider hidden/>
            <Form>
              <div id="my-account"></div>
              </Form>
              </Fragment>
          
          </Dimmer.Dimmable>
        </Container>
    )
  }
}
export default withRouter(Account);