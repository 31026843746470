import React, { Component, Fragment } from 'react';
import {
  Button,
  Container,
  Header,
  Image,
  Segment,
  Form,
  Checkbox,
  Grid,
  Divider,
  Rating,
  Icon,
  Message,
  Statistic,
  Sidebar,
  Label,
  Dropdown,
  Placeholder,
  Menu,
  Loader,
  Responsive,
  Transition,
  Modal
} from 'semantic-ui-react';
import { withRouter,Link } from "react-router-dom";
import conf from './../../assets/img/conf.jpg'
import ReadMoreAndLess from 'react-read-more-less';
//import brandLogo from './../../assets/img/brandlogo.png';
import './../../assets/css/main.css';
import add from './../../assets/img/add.png';
import download from './../../assets/img/download.png';
import axios from 'axios';
var DEFAULTPLAN='Annual';

class PeerHeader extends Component {
  state={addcompanies:false,options:[],selected:[],selected_option:[],maxselected:false,hascompanies:false,event_list:[],gettingcomp:false,maxpeers:1,user:{}}
  
  addgroup = () => {
    this.setState({addcompanies:true})
  }
 handleChange = (e, { value }) => {
  var new_value = null;
  var selected_option = [...this.state.selected_option];
  var selected = [...this.state.selected];
  if(selected.length > value.length){
    Array.prototype.diff = function(a) {
    return this.filter(function(i) {return a.indexOf(i) < 0;});
    };
    var diff = selected.diff(value);
    selected.map((x,index) => {
      if(x == diff[0]){
      //  console.log(x,diff[0],"found")
        selected.splice(index,1)
      }
    })
    //console.log(selected,"s")
    selected_option.map((x,index) => {
      if(x.value == diff[0]){
        selected_option.splice(index,1)
      }
    })
    this.setState({selected:selected,selected_option:selected_option})
    //console.log(selected,selected_option,value,"v")
  }
  else{
    if(selected.length< this.state.maxpeers){
     this.state.options.map(obj => {
  //console.log(obj,value,"v")
  if(obj.value==value[value.length-1]){
     new_value = obj.value;
     selected_option.push(obj);
     }
 })
 
 //console.log(new_value);
 if(new_value){
  selected.push(new_value);

  this.setState({selected_option:selected_option})
 // selected = selected.map(x => x.value);
  this.setState({ selected:selected })}
  }
  else{
    var that = this;
    this.setState({maxselected:true})
    setTimeout(function(){that.setState({maxselected:false})},5000)   
  }

  }
  
  }

 
 handleSearchChange = (e, { searchQuery }) =>{
  this.setState({gettingcomp:true})
  axios.post('search_cname',{name:searchQuery}).then(data => {
    if(data.data){
      if(this.state.selected_option.length>0){
        //console.log(this.state.selected,"s");
        this.state.selected_option.map(x => {
          data.data.push(x)
        })
        
      }
      //console.log(this.state.selected,data,data,"fetch")
      this.setState({options:data.data,gettingcomp:false})
    }
  }).catch(err => {

  })
 } 
  componentDidMount(){
    var user = JSON.parse(localStorage.user_details);
    this.setState({user:user})
    //user.plan == 'Month'
     if(user.plan=='Month'){
      this.setState({maxpeers:process.env.REACT_APP_CP_MTM})
     }
     else if(user.plan=='Annual'){
      this.setState({maxpeers:process.env.REACT_APP_CP_ANN})
     }
     // else{
     //  this.props.history.push('/dashboard');
     // }

     this.props.loading();
        
          axios.get('fetch_peers').then(values => {
            var options = [...this.state.options];
            var selected = values.data.map(x => x.value);
            values.data.map(x => {
              options.push(x);
            })
            var hascompanies=false;
            if(selected.length>0){
              hascompanies=true;
            }
            this.setState({selected:selected,options:options,selected_option:values.data,hascompanies:hascompanies},() => {
              if(this.state.hascompanies){
                 axios.post('peer_participation/'+this.props.year,{plan:this.state.user.plan}).then(list => {
                     this.setState({event_list:list.data})
                    this.props.action(this.state.selected_option,list.data)
                  }).catch(err => {
                    console.log(err);
                  })
              }
              else{
               this.props.resetaction()
              }
             
            })
          }).catch(err => {
            console.log(err);
          })
  
      
   


  }

  savePeers = (key) => {
     this.props.loading();
     axios.post('save_peers',{company:this.state.selected}).then(data => {
      if(data.data){
        if(this.state.selected.length!=0){
            this.setState({addcompanies:false,hascompanies:true})
            axios.post('peer_participation/'+this.props.year,{plan:this.state.user.plan}).then(list => {
              this.setState({event_list:list.data})
              this.props.action(this.state.selected_option,list.data)
            }).catch(err => {
              console.log(err);
            })
        }
        else{
          
            this.setState({addcompanies:false,hascompanies:false,event_list:[]})
            this.props.resetaction(this.state.selected_option,[])
          }
          

         
        
        
      }
    }).catch(err => {
      console.log(err);
    })
    var plan_name=[{name:DEFAULTPLAN}];
    // if(this.state.user.plan=='Free'){
    //   var resources = localStorage.getItem('resources');
    //   resources = JSON.parse(resources);
    //   var free_id = process.env.REACT_APP_FREE_PLAN;
    //    plan_name = resources.filter(x => {
    //     if(x.rid==free_id){
    //       return x;
    //     }
    //   })

    // }
    // else if(this.state.user.plan=='Month'){
    //   var resources = localStorage.getItem('resources');
    //   resources = JSON.parse(resources);
    //   var mtm_id = process.env.REACT_APP_MTM_PLAN;
    //   plan_name = resources.filter(x => {
    //     if(x.rid==mtm_id){
    //       return x;
    //     }
    //   })
    // }
    // else{
    //   var resources = localStorage.getItem('resources');
    //   resources = JSON.parse(resources);
    //   var ann_id = process.env.REACT_APP_ANN_PLAN;
    //   var etp_id = process.env.REACT_APP_ETP_PLAN;
    //   plan_name = resources.filter(x => {
    //     if(x.rid==ann_id || x.rid==etp_id){
    //       return x;
    //     }
    //   })
    // }
    //var plan  = this.state.user.plan=='Free'?'Starter':this.state.user.plan=='Month'?'Flexible':'Premium'
     axios.post('record',{company_id:this.state.selected,identity:6,plan_type:plan_name[0].name}).then(done => {

    }).catch(err => {
          console.log(err,"analytics error");
    })
  }

  removePeer = (value) => event => {
    var selected_option = [...this.state.selected_option];
    var selected = [...this.state.selected];
   // var diff = selected.diff(value);
    //console.log(diff,selected,"diff");
    selected.map((x,index) => {
      if(x == value){
      //  console.log(x,value,"found")
        selected.splice(index,1)
      }
    })
    //console.log(selected,"s")
    selected_option.map((x,index) => {
      if(x.value == value){
        selected_option.splice(index,1)
      }
    })
    //console.log(selected,selected_option,"check")
    this.props.loading();
    this.setState({selected:selected,selected_option:selected_option},() => {
      this.savePeers();
    })

  }
  componentDidUpdate(prevProps) {
    //console.log(prevProps,JSON.stringify(this.props.filters)!=JSON.stringify(prevProps.filters),"prev")
      // Typical usage (don't forget to compare props):
      if(this.props.year){
          if(this.props.year!=prevProps.year){
            this.savePeers();
        
         }
      }
     
  }

  resetPeers = () => {
    this.setState({addcompanies:false,hascomapnies:false,selected:[],selected_option:[]},() => {
      this.savePeers('reset');
    })
    
  }

  downloadfree = () => {
    this.setState({upgrade:true})
    setTimeout(() => {
      this.setState({upgrade:false})
    },2000)
  }
  downloadFile=()=>{
    var {selected} = this.state;
    var plan = this.state.user.plan;
    if(plan=='Free1' || plan=='Free' || plan=='Month') {
      this.downloadfree();
    }
    else{
      this.props.download(selected);
    }
  }
 handleUpgradeClick=text=>{
  const data=JSON.stringify({identity:14,link:text});
  const config={headers:{"Content-Type":"application/json"}};
  axios.post("/record",data,config)
  .then(() =>console.log("success"))
  .catch((error) =>console.log(error)); 

 }
  handleClose = () => this.setState({ upgrade: false });

  render(){
    const { selected } =this.state;
    const renderLabel = label => ({
      color: 'blue',
      content: label.text,
    })
    //console.log(this.state,"st")
    return(
      <Fragment>
      <style>{`
        .right.item >span {
          cursor: pointer;
        }`}
        </style>
      {this.state.addcompanies && this.state.maxselected &&<div className="filters-peer">
      <Container fluid>
        <Grid style={{padding:'0 25px'}}>
        <Grid.Column>{this.state.user.plan!='Annual' && <Transition unmountOnHide animation='fade' duration={800} visible={this.state.maxselected}><Header className="error-header" as="h5" inverted>Can only select {this.state.maxpeers} companies at a time in this plan, <Link to="/account" onClick={() =>this.handleUpgradeClick("peer group")}>upgrade</Link> to select more companies</Header></Transition>}
        {this.state.user.plan=='Annual' && <Transition unmountOnHide animation='fade' duration={800} visible={this.state.maxselected}><Header className="error-header" as="h5" inverted>Can only select 5 companies at a time</Header></Transition>}
               </Grid.Column>
        </Grid>
        </Container> 
        </div>}
         {this.state.upgrade && <div className="filters-peer" style={{padding:'8px'}}><Container fluid><Transition unmountOnHide animation='fade' duration={800} visible={this.state.upgrade}><Header  className="error-header" as="h5" inverted><Link to="/account" onClick={() =>this.handleUpgradeClick("download peers")}>Upgrade</Link> now to save these details for your record</Header></Transition></Container></div>}

      <Menu   
             borderless
              size='massive'
              secondary
              inverted
              stackable
              fluid
              className="filter-header"
              
              style={{padding:'10px 0px',margin:0}}
            >
              <Container fluid style={{padding:'0px 20px'}}>
              {!this.state.addcompanies &&<Menu.Item disabled={this.state.user.plan=="Free"} onClick={this.addgroup} style={{fontSize: '14px'}}>PEER GROUP &nbsp; <Image src={add} />
              </Menu.Item>}
               
               {this.state.addcompanies &&<Dropdown
                  item
                  multiple
                  selection
                  search
                  loading={this.state.gettingcomp}
                  minCharacters={2}
                  onChange={this.handleChange}
                  onSearchChange={this.handleSearchChange}
                  value={this.state.selected}
                  className="add-peers-box"
                  options={this.state.options}
                  placeholder='Enter Company Name'
                  renderLabel={renderLabel}
                  disabled={this.state.user.plan=='Free'}
                />}
                {this.state.addcompanies && <Menu.Item><Button primary size="tiny" onClick={this.savePeers}>Apply</Button>&nbsp;&nbsp;<Button inverted onClick={() => this.setState({addcompanies:false})} basic size="tiny">Cancel</Button>&nbsp;&nbsp;<Button inverted onClick={this.resetPeers} basic size="tiny">Reset</Button></Menu.Item>}
                {!this.state.addcompanies && this.state.hascompanies && <Responsive minWidth="768"><Menu.Item>{this.state.selected_option.map((obj,index) =>{return(<Label key={index} size="large" style={{background:'transparent',border:'1px solid #fff'}}   basic>{obj.text}    <Icon onClick={this.removePeer(obj.value)} name='delete' /></Label>)})} &nbsp;</Menu.Item></Responsive>}
                {!this.state.addcompanies && this.state.hascompanies && <Responsive maxWidth="768"><Menu.Item><Grid style={{width: '100%'}} fluid >{this.state.selected_option.map((obj,index) =>{return(<Grid.Column key={index} width={8}><Label inverted size="large" style={{background:'transparent',border:'1px solid #fff',color:'#fff'}}   basic>{obj.text}    <Icon onClick={this.removePeer(obj.value)} name='delete' /></Label></Grid.Column>)})}</Grid></Menu.Item></Responsive>}
                {!this.state.addcompanies &&<Menu.Item><Button disabled={this.state.user.plan=='Free'} onClick={this.resetPeers} color="blue" size="tiny">Reset</Button>&nbsp;
                 </Menu.Item>           }    
                <Menu.Item position="right">
                 {window.innerWidth>768 && (this.state.user.plan=='Free' || this.state.user.plan=='Month' || this.state.plan=='Free1') &&
                 <span><Image verticalAlign="middle" src={download} onClick={this.downloadFile}/> {(this.state.user.plan=='Free' || this.state.user.plan=='Free1' || this.state.user.plan=='Month') && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}
                 </span>}
                                   {window.innerWidth>768 && this.state.user.plan!='Free' && this.state.user.plan!='Free1' && this.state.user.plan!='Month' && (Object.keys(this.props.speaker).length>0 || this.props.list.length>0) &&
                                       <span><Image verticalAlign="top" src={download} onClick={this.downloadFile}/> {this.state.user.plan=='Free' && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}</span>
                                   }
                                   {window.innerWidth<768 && (this.state.user.plan=='Free' || this.state.user.plan=='Free1' || this.state.user.plan=='Month') && <span><Image verticalAlign="middle" src={download} onClick={this.downloadFile}/> {(this.state.user.plan=='Free' || this.state.user.plan=='Free1' || this.state.user.plan=='Month') && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}
                                      </span>
                                   }
                 {window.innerWidth<768 && this.state.user.plan!='Free' && this.state.user.plan!='Free1' && this.state.user.plan!='Month' &&  (Object.keys(this.props.speaker).length>0 || this.props.list.length>0) &&<span><Image verticalAlign="middle" src={download} onClick={this.downloadFile}/> {(this.state.user.plan=='Free' || this.state.user.plan=='Free1' || this.state.user.plan=='Month') && <Icon corner='top right' name='lock' style={{color: '#fff'}} />}
                                      </span>}
               
              
               </Menu.Item>
              </Container>
        </Menu>
      {/*   <Modal  open={this.state.upgrade}
            onClose={this.handleClose} 
            size="small"
            >
          <Modal.Content >
          <Header as="h2" icon textAlign="center">
          <Icon name="lock"/><br/>
          <Link to="/account">Upgrade</Link> now to save these details for your records
          </Header>
          </Modal.Content>
        </Modal>*/}
        </Fragment>
    )
  }
}

export default withRouter(PeerHeader);