import React, { Component } from 'react';
import axios from 'axios';
import PreviewConferencePage from './PreviewConferencePage.jsx';
import {Placeholder} from 'semantic-ui-react';

class TestingPage extends Component {
	state={loading:true,data:{}}
	async componentWillMount() {
		console.log("TestingPage mount");
		const { tId, cId} = this.props.match.params;
		console.log(this.props.match.params)
		const data = {token:tId,id:cId};
		// axios.post('/verify_security_token',data).then((data)=>{

		// 	this.setState({loading:false,data})
		// }).catch(()=>{
		// 	this.props.history.push("/");
		// })
		try{
			var result = await axios.post('/verify_security_token',data);
			this.setState({loading:false,data:result})
		}catch(err){
            this.props.history.push("/");
		} 

        
	}
	handleEvent = () =>{
		return "er";
	}
	render() {
		console.log("TestingPage render");
		return(<div style = {{backgroundColor:"white"}}>
			{ !this.state.loading && <PreviewConferencePage events={true} haveData={true} data={this.state.data} unsave={this.handleEvent}  /> 
			||<Placeholder><Placeholder.Line/></Placeholder> }
		</div>)
		
	}
}
export default TestingPage;