import LoginPage from './../views/Pages/LoginPage.jsx';
import SearchEvents from './../views/Pages/SearchEvents.jsx';
import DashboardPage from './../views/Pages/Dashboard.jsx';
import AccountPage from './../views/Pages/Account.jsx';

import EventsPage from './../views/Pages/MyEventsPage.jsx';

import HelpPage from './../views/Pages/HelpPage.jsx';
import ComparePeers from './../views/Pages/ComparePeers.jsx';
import TestingPage from './../views/Pages/testing.jsx';


const allRoutes=[
{
	path:'/',
	component:LoginPage
},
{
	path:'/search-events',
	component:SearchEvents
},
{
	path:'/dashboard',
	component:DashboardPage
},
{
	path:'/account',
	component:AccountPage
},
{
	path:'/my-events',
	component:EventsPage
},
{	path:'/help',
	component:HelpPage
},
{
	path:'/compare-peers',
	component:ComparePeers
},
{
	path:'/preview/:tId/:cId',
	component:TestingPage
}
]
export default allRoutes;